const CaretLeft = ({ ...props }) => {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.2929 5.99996C13.4882 5.8047 13.8048 5.8047 14 5.99996L14.7071 6.70707C14.9024 6.90233 14.9024 7.21891 14.7071 7.41418L9.76779 12.3535L14.7071 17.2929C14.9024 17.4881 14.9024 17.8047 14.7071 18L14 18.7071C13.8048 18.9023 13.4882 18.9023 13.2929 18.7071L7.29292 12.7071C7.09765 12.5118 7.09765 12.1952 7.29292 12L13.2929 5.99996Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default CaretLeft
